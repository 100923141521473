import React,{useState, useEffect} from "react";
import { brandLogo } from "../../assets";
import { Link } from "react-router-dom";
import ScrollToTopButton from "../../ScroolToTop";

const MobileFooter = () => {
  const [currentYear, setCurrentYear] = useState();

  useEffect(() => {
    setCurrentYear(new Date().getFullYear());
  }, []);


  const smoothScrollTo = (id) => {
    const element = document.getElementById(id);
    if (element) {
      window.scrollTo({
        behavior: "smooth",
        top: element.offsetTop - 100,
      });
    }
  };
  return (
    <>
      <div className="md:hidden flex justify-end text-[34px] px-[7%]">
        <ScrollToTopButton />
      </div>
      <div
        className="md:hidden flex flex-col bg-[#273B9D] text-[#FFFFFF] font-supreme w-full py-10"
        style={{ fontFamily: "supreme" }}
      >
        <div className="flex flex-col gap-5 w-[85%] m-auto">
          <div className="flex flex-col gap-5 w-[20%">
            {/* <div>
              <img src={brandLogo} alt="" />
            </div> */}
            <div className="flex flex-col font-medium text-[14px] leading-[21px] gap-3 w-[40%">
              <p>
              Lagos Head Office - St. Peters House: 6th Floor <br /> 3, Ajele Street, Off Broad Street, Lagos island, lagos state
              
              </p>
              <p>Ibadan branch- 7/8 Otudeko Street Anfani off Challenge Ibadan , Oyo state</p>
              <p>Abuja branch - 22 Gwandu Street, AREA 11 Gariki Abuja</p>
              <p>Info@slobaj.com</p>
            </div>
          </div>

          <div className="flex justify-between">
            <div className="flex flex-col gap-2">
              <p
                className="font-bold text-[20px] leading-[30px]"
                style={{ fontFamily: "chubbo" }}
              >
                Services
              </p>
              <ul className="flex flex-col font-medium text-[14px] leading-[21px] gap-3">
                <li
                  onClick={() => smoothScrollTo("construction")}
                  className="cursor-pointer"
                >
                  Construction
                </li>
                <li
                  onClick={() => smoothScrollTo("engineering")}
                  className="cursor-pointer"
                >
                  Engineering
                </li>
                <li
                  onClick={() => smoothScrollTo("procurement")}
                  className="cursor-pointer"
                >
                  procurements
                </li>
                <li
                  onClick={() => smoothScrollTo("maintainance")}
                  className="cursor-pointer"
                >
                  Maintainance
                </li>
              </ul>
            </div>

            <div className="flex flex-col gap-2">
              <p
                className="font-bold text-[20px] leading-[30px]"
                style={{ fontFamily: "chubbo" }}
              >
                Company
              </p>
              <ul className="flex flex-col font-medium text-[14px] leading-[21px] gap-3">
                <li
                  onClick={() => smoothScrollTo("team")}
                  className="cursor-pointer"
                >
                  Our team
                </li>
                <li>
                  <Link to="/about">About us</Link>
                </li>
                <li
                  onClick={() => smoothScrollTo("careere")}
                  className="cursor-pointer"
                >
                  Careers
                </li>
              </ul>
            </div>

            <div className="flex flex-col gap-2">
              <p
                className="font-bold text-[20px] leading-[30px]"
                style={{ fontFamily: "chubbo" }}
              >
                Projects
              </p>
              <ul className="flex flex-col font-medium text-[14px] leading-[21px] gap-3">
              <li>completed</li>
              <li onClick={() => smoothScrollTo("client")} className="cursor-pointer">clients</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="flex lg:hidden justify-between items-center w-[85%] m-auto py-2">
        <img src={brandLogo} alt="" className="w-[80px]"/>
        <p className="text-[#6F748D] font-normal text-[14px] leading-[21px]" style={{ fontFamily: "supreme-100" }}>&copy; {currentYear} Slobaj.All rights reserved</p>
      </div>
    </>
  );
};

export default MobileFooter;
