import React from 'react'
import { vector } from '../../assets'

const Project = () => {
  return (
    <div className="flex justify-between w-[85%] m-auto py-5">
      <div className="flex flex-col gap-3 py-3">
        <p className="font-bold text-[#B80A47] text-[20px]" style={{fontFamily:"supreme"}}>Our projects</p>
        <p className="text-[24px] text-[#6F748D] font-bold w-full md:w-[70%]" style={{fontFamily:"chubbo"}}>Diverse National and International Custom and  Engineering projects</p>
      </div>
      <div className='hidden md:block'>
        <img src={vector} alt="" />
      </div>
    </div>
  )
}

export default Project