import React, { useEffect } from "react";
import "aos/dist/aos.css";
import AOS from "aos";
import {
  npaproject1,
  npaproject2,
  npaproject3,
  npaproject4,
  npaproject5,
  npaproject6,
} from "../../assets";

const NpaProject = () => {
  useEffect(() => {
    AOS.init({ duration: 700 });
  }, []);
  return (
    <>
      <div
        className="flex flex-col items-center gap-10 m-auto my-10 md:my-20 overflow-hidden"
        style={{ fontFamily: "supreme" }}
      >
        <div className="flex w-[85%] m-auto">
          <p
            data-aos="fade-down"
            className="font-bold text-[20px] lg:text-[32px] text-[#3D475B]"
            style={{ fontFamily: "chubbo" }}
          >
            NPA Project
          </p>
        </div>
        <div
          data-aos="fade-left"
          className="flex gap-3 w-[85%] md:w-[94%] m-aut"
        >
          <div>
            <img src={npaproject1} alt={""} />
          </div>

          <div className="flex gap-3">
            <div className="flex flex-col gap-3">
              <div>
                <img src={npaproject2} alt={""} />
              </div>
              <div>
                <img src={npaproject4} alt={""} />
              </div>
              <div>
                <img src={npaproject5} alt={""} />
              </div>
            </div>

            <div className="flex flex-col gap-3">
              <div>
                <img src={npaproject3} alt="" />
              </div>
              <div>
                <img src={npaproject6} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NpaProject;
