import React from "react";
import { ancentSquare, contactFlowers, square1 } from "../../assets";
import NavBar from "../nav_bar/NavBar";
import Footer from "../footer/Footer";
import Form from "./Form";

const Contact = () => {

  return (
    <>
      <NavBar />
      <div className="flex flex-col gap-10 mb-5 md:mb-32 mt-5 md:mt-10" style={{fontFamily:"supreme"}}>
        <div className="flex flex-col font-bold gap-2 justify-center items-center text-center">
          <p className=" text-[42px] text-[#273B9D]" style={{fontFamily:"chubbo"}}>Contact Us</p>
          <p className=" text-[20px] text-[#6F748D] leading-[30px] w-[85%]">
            Fill out the form and a consultant would reach out to you within 24
            hours
          </p>
        </div>
        <div className="flex w-full items-center">
          <div className="hidden md:block w-[5%] relative top-32 -z-10">
            <img src={ancentSquare} alt="" />
          </div>

          <div className="flex flex-col md:flex-row w-[85%] m-auto gap-3">
            <div className="catalog flex flex-col justify-between w-full md:w-[40%]">
              <div className="flex flex-col font-bold gap-2 text-[#ffff] p-5">
                <p className=" font-chubbo text-[32px]" style={{fontFamily:"chubbo"}}>Book with Us</p>
                <p className=" text-[20px]">
                  Let's turn your projects into gems
                </p>
              </div>
              <img src={contactFlowers} alt="" />
            </div>
            <Form />
          </div>
          <div className="hidden md:flex justify-center items-end relative top-80">
            <img src={square1} alt="" />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
