import React, { useEffect } from "react";
import "aos/dist/aos.css";
import AOS from "aos";
import {
  ancentSquare,
  cilvilEngr,
  consultancy,
  customdesignimg,
  greySquare,
  maintainancewk2,
  oilGas2,
  square1,
  transformer,
} from "../../assets";
import NavBar from "../nav_bar/NavBar";
import Footer from "../footer/Footer";
import ContactBtn from "../news_letter/ContactBtn";

const OurServices = () => {
  useEffect(() => {
    AOS.init({ duration: 700 });
  }, []);
  return (
    <>
      <NavBar />
      <div
        className="flex flex-col overflow-hidde"
        style={{ fontFamily: "supreme" }}
      >
        {/* catalog section */}
        <div className="flex pt-5 text-[#ffff] h-[253px] bg-[url('/src/assets/images/backgrounImg.svg')] bg-no-repeat bg-center bg-cover">
          <div className="flex flex-col font-bold gap-3 w-[85%] m-auto">
            <p className="font-supreme text-[20px]">Our Services</p>
            <p
              className="flex font-chubbo text-[32px] lg:text-[42px]"
              style={{ fontFamily: "chubbo" }}
            >
              Our Catalog
            </p>
          </div>
        </div>

        {/* Rendered services section  */}
        <div className="flex justify-between items-center md:gap-5 lg:gap-5 my-10 md:my-20 lg:my-5 w-full overflow-hidden">
          <div className="hidden md:flex w-[4%] relative bottom-80">
            <img src={ancentSquare} alt={""} />
          </div>

          <div className="flex justify-center w-[85%] m-auto flex-col gap-5">
            <div data-aos="fade-up" className="flex flex-col md:flex-row gap-3">
              <div className="flex flex-col gap-3 md:w-[50%]">
                <img src={cilvilEngr} alt="" />
                <p
                  className="font-bold text-[24px]"
                  style={{ fontFamily: "chubbo" }}
                >
                  Civil Engineering{" "}
                </p>
                <p className="font-medium text-[14px] text-[#6F748D] leading-[21px] w-full">
                  Our team of professionals blend creativity, knowledge and
                  analytical tools by applying problem solving techniques of
                  engineering centred on creating technologies to reach human
                  needs.
                </p>
              </div>

              <div className="flex flex-col gap-3 md:w-[50%]">
                <img src={transformer} alt="" />
                <p
                  className="font-bold text-[24px]"
                  style={{ fontFamily: "chubbo" }}
                >
                  Electrical Engineering{" "}
                </p>
                <p className="font-medium font-supreme text-[14px] text-[#6F748D] leading-[21px]">
                  Our team of professionals blend creativity, knowledge and
                  analytical tools by applying problem solving techniques of
                  engineering centred on creating technologies to reach human
                  needs.
                </p>
              </div>
            </div>

            <div data-aos="fade-up" className="flex flex-col md:flex-row gap-3">

              <div className="flex flex-col gap-3 md:w-[50%]">
                <img src={maintainancewk2} alt="" />
                <p
                  className="font-bold text-[24px]"
                  id="maintainance"
                  style={{ fontFamily: "chubbo" }}
                >
                  Maintenance
                </p>
                <p className="font-medium text-[14px] text-[#6F748D] leading-[21px] lg:w-[375px] ">
                  We help in keeping the the standard of your facilities up to
                  date by providing the necessary maintenance for your
                  facilities.
                </p>
              </div>

              <div className="flex flex-col gap-3 md:w-[50%]">
                <img src={oilGas2} alt="" />
                <p
                  className="font-bold text-[24px]"
                  style={{ fontFamily: "chubbo" }}
                >
                  Upstream & Downstream
                </p>
                <p className="font-medium text-[14px] text-[#6F748D] leading-[21px] ">
                  Our team of professionals blend creativity, knowledge and
                  analytical tools by applying problem solving techniques of
                  engineering centred on creating technologies to reach human
                  needs.
                </p>
              </div>
            </div>

            <div
              data-aos="fade-right"
              className="flex flex-col md:flex-row gap-3"
            >
              <div className="flex flex-col gap-3 md:w-[50%]">
                  <img src={customdesignimg} alt="" />
                <p
                  className="font-bold text-[24px]"
                  style={{ fontFamily: "chubbo" }}
                >
                  Custom designs
                </p>
                <p className="font-medium text-[14px] text-[#6F748D] leading-[21px] w-full">
                Through our custom architecture designs, we partner with clients, providing guidance and support to create a livable and sustainable future, thereby enhancing community growth.

                </p>
              </div>

              <div
                data-aos=""
                className="flex flex-col gap-3 w-[100%] md:w-[50%]"
              >
                  <img src={consultancy} alt="" />
                <p
                  className="font-bold text-[24px]"
                  style={{ fontFamily: "chubbo" }}
                >
                  Consultancy{" "}
                </p>
                <p className="font-medium text-[14px] text-[#6F748D] leading-[21px] w-full">
                  offer valuable expertise and guidance for your businesses
                  seeking to improve performance, address challenges, trainings
                  and achieve success, so organisations can make informed
                  decisions and support their growth and prosperity.
                </p>
              </div>
            </div>
          </div>
          <div className="hidden md:flex w-[4% relative bottom-60 right-8 -mr-8">
            <img src={square1} alt="" />
          </div>
        </div>
      </div>
      <div className="relative bottom-96 lg:-mb-72 md:-mb-32 w-[4%] hidden md:block">
        <img src={greySquare} alt="" />
      </div>
      <ContactBtn />
      <Footer />
    </>
  );
};

export default OurServices;
