import React, { useEffect } from "react";
import "aos/dist/aos.css";
import AOS from "aos";
import {
  amdaLogo,
  arrow,
  consultationBade,
  culturLogo,
  designBadge,
  firoLogo,
  ncaLogo,
  ncsLogo,
  nigPortAut,
  premiumBadge,
  pricingBadge,
} from "../../assets";

export const OurClient = () => {
  useEffect(() => {
    AOS.init({ duration: 700 });
  }, []);
  return (
    <>
      <div
        id="client"
        className="flex flex-col md:mb-8 mb-0  flex-shrink-0 overflow-hidden my-16 md:my-28 "
        style={{ fontFamily: "supreme" }}
      >
        {/* clients logo section  */}
        <div className="flex flex-col gap-5">
          <p
            data-aos="fade-right"
            className="font-bold text-[24px] lg:text-[42px] text-[#3D475B] w-[85%] m-auto"
            style={{ fontFamily: "chubbo" }}
          >
            Our Clients
          </p>
          <div className="marquee-content flex gap-10 overflow-hidde flex-shrink-0">
            <div className="">
              <img src={firoLogo} alt={firoLogo} />
            </div>
            <div>
              <img src={ncaLogo} alt={ncaLogo} />
            </div>
            <div>
              <img src={amdaLogo} alt={""} />
            </div>
            <div className="hidden md:block">
              <img src={culturLogo} alt={""} />
            </div>
            <div>
              <img src={nigPortAut} alt={""} />
            </div>
            <div>
              <img src={ncsLogo} alt="" />
            </div>
          </div>

          <div
            data-aos="fade-up"
            className="hidden flex-col gap-3 w-[85%] m-auto items-center"
          >
            <div className="flex gap-3">
              <div className="">
                <img src={culturLogo} alt={""} className="w-[80px]" />
              </div>
              <div>
                <img src={ncaLogo} alt={ncaLogo} className="w-[80px]" />
              </div>
              <div>
                <img src={amdaLogo} alt={""} className="w-[80px]" />
              </div>
            </div>
            <div className="flex gap-3">
              <div className="">
                <img src={firoLogo} alt={firoLogo} className="" />
              </div>
              <div>
                <img src={nigPortAut} alt={""} className="" />
              </div>
              <div>
                <img src={ncsLogo} alt="" className="" />
              </div>
            </div>
          </div>
        </div>

        {/* trusted engineering partner section  */}
        <div
          id="engineering"
          data-aos="fade-left"
          className=" flex flex-col gap-3 md:my-20 my-5 w-[85%] m-auto "
        >
          <div className="flex flex-col  lg:flex-row justify-between gap-10">
            <div className="flex justify-between">
              <p
                className="flex md:text-[42px] text-[24px] font-bold lg:leading-[63px] leading-[unset] w-[123px] md:w-[321px]"
                style={{ fontFamily: "chubbo" }}
              >
                We are your trusted Engineering partner{" "}
              </p>

              <button className="group flex items-center justify-center border-[2px] border-[#273B9D] rounded-full md:h-[3em] h-[3em] transition-transform duration-300 hover:w-[12em] w-[4em] p-[10px]">
                <div className="flex items-center w-full hover:items-center hover:justify-center">
                  <img src={arrow} alt="" className="group-hover:rotate-45" />
                  <span className=" opacity-0 group-hover:opacity-100 text-[#3D475B] whitespace-nowrap">
                    Explore More
                  </span>
                </div>
              </button>
            </div>

            <p className="flex font-medium text-[16px] leading-[24px] text-[#6F748D] w-full lg:w-[486px] justify-start my-5 lg:my-[unset]">
              At Slobaj, trust is the foundation of our relationships. With a
              commitment to excellence, transparent communication, and
              collaborative teamwork, we are your trusted building partner. Our
              experience, integrity, and client-centric approach ensure that
              your project is in capable hands from start to finish."
            </p>
          </div>

          <div className="flex flex-col lg:flex lg:flex-row gap-5 md:grid grid-cols-2">
            <div className="flex flex-col  border-[1px] border-[#3D475B] border-opacity-[0.2] p-2 gap-3 rounded-md">
              <div>
                <img src={premiumBadge} alt="" />
              </div>
              <div className="flex flex-col gap-3">
                <p
                  className="font-bold text-[16px] leading-[24px]"
                  style={{ fontFamily: "chubbo" }}
                >
                  Premium Quality{" "}
                </p>
                <p className="font-medium text-[14px] leading-[21px] font-supreme w-[241px">
                  At Slobaj, our hallmark is premium quality—precision,
                  integrity, and enduring excellence in every project."
                </p>
              </div>
            </div>

            <div className="flex flex-col  border-[1px] border-[#3D475B] border-opacity-[0.2] p-2 gap-3 rounded-md">
              <div>
                <img src={consultationBade} alt="" />
              </div>
              <div className="flex flex-col gap-3">
                <p
                  className="font-bold text-[16px] leading-[24px]"
                  style={{ fontFamily: "chubbo" }}
                >
                  Consultation{" "}
                </p>
                <p className="font-medium text-[14px] leading-[21px] ">
                  Expert consultation tailored to your needs, guiding you from
                  concept to completion."
                </p>
              </div>
            </div>

            <div className="flex flex-col  border-[1px] border-[#3D475B] border-opacity-[0.2] p-2 gap-3 rounded-md w-[273px">
              <div>
                <img src={designBadge} alt="" />
              </div>
              <div className="flex flex-col gap-3">
                <p
                  className="font-bold text-[16px] leading-[24px]"
                  style={{ fontFamily: "chubbo" }}
                >
                  Custom Design{" "}
                </p>
                <p className="font-medium text-[14px] leading-[21px] w-[241px">
                  Unlock bespoke brilliance with our custom design tailored to
                  reflect your vision, preferences, and unique style."
                </p>
              </div>
            </div>

            <div className="flex flex-col  border-[1px] border-[#3D475B] border-opacity-[0.2] p-2 gap-3 rounded-md">
              <div>
                <img src={pricingBadge} alt="" />
              </div>
              <p
                className="font-bold text-[16px] leading-[24px]"
                style={{ fontFamily: "chubbo" }}
              >
                Best Pricing{" "}
              </p>
              <p className="font-medium text-[14px] leading-[21px] font-supreme">
                Transparent pricing, delivering quality projects within your
                budget, without compromising on excellence.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
