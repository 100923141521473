import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { building, consultancy, hero2Img, lightmask, roundedCable } from "../../assets";

export const Construction = () => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 1,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <div
        className="overflow-hidden pb-5 md:pb-10"
        style={{ fontFamily: "supreme" }}
      >
        <Slider {...settings} className="">
          <div>
            <div className="flex lg:h-[100vh] h-[100%] my-5">
              <div className="flex flex-col md:flex-row justify-center w-[85%] m-auto">
                <div className="flex flex-col w-full lg:w-[55%] gap-5">
                  <p
                    className="font-bold text-[14px] md:text-[32px] lg:text-[42px] text-[#3D475B] w-[205px] md:w-[unset] leading-[21px] md:leading-[unset]"
                    style={{ fontFamily: "chubbo" }}
                  >
                    <span className="text-[#273B9D]">Construction</span> is more
                    than buildings - we make{" "}
                    <span className="text-[#B80A47]">Dreams</span> become{" "}
                    <span className="text-[#0D93F4]">Structures</span>
                  </p>
                  <p className="font-medium md:text-[14px] md:leading-[21px] leading-[18px] text-[#6F748D] lg:w-[594px] w-[313px] text-[12px]">
                    At Slobaj Engineering, we're not just constructing
                    buildings; we're turning dreams into concrete realities.
                    With meticulous planning and unwavering dedication, Expect
                    unmatched quality, timely delivery, and personalized service
                    every step of the way.
                  </p>

                  <Link to="/contact">
                    <button className="btn flex justify-center items-center border-[4px] border-[#273B9D] px-[28px] py-[8px]   xl:w-[40%] rounded-full text-[#3D475B] text-[20px] font-bold hover:text-[#ffff] ">
                      Get started
                    </button>
                  </Link>
                </div>
                <div className="flex -z-10 gap-2 mt-10">
                  <div className="relative md:top-28 top-20  left-16 -ml-16 ">
                    <img src={lightmask} alt="" className="lg:h-[354px] h-[unset] rounded-xl lg:rounded-none" />
                  </div>
                  <div>
                    <img
                      src={building}
                      alt=""
                      className=" lg:h-[418px] lg:w-[384px] h-[unset] w-[unset] rounded-xl lg:rounded-none"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="flex flex-col gap-[50px] md:gap-3 w-[85%] my-5 lg:my-10 m-auto justify-center items-center lg:h-[100vh]">
              <div className="flex gap-5 flex-col lg:flex-row justify-between">
                <p className="font-bold text-[14px] md:text-[32px] lg:text-[42px] text-[#3D475B] w-[205px] md:w-[unset]">
                  <span
                    className="text-[#273B9D]"
                    style={{ fontFamily: "chubbo" }}
                  >
                    Consultancy
                  </span>{" "}
                  is more than advice; we{" "}
                  <span className="text-[#0D93F4]">transform ideas</span> into{" "}
                  <span className="text-[#B80A47]">actionable strategies.</span>
                </p>

                <div className="flex flex-col gap-[20px] md:gap-3">
                  <p className="flex font-medium text-[14px] text-[#6F748D] leading-[21px] w-[313px] md:w-[unset]">
                    Our Consultancy Services offer valuable expertise and
                    guidance for your businesses seeking to improve performance,
                    address challenges, trainings and achieve success, so
                    organisations can make informed decisions and support their
                    growth and prosperity.
                  </p>

                  <Link to="/contact">
                    <button className="btn flex justify-center items-center border-[4px] border-[#273B9D] px-[28px] py-[8px]  md:w-[40%] rounded-full text-[#3D475B] text-[20px] font-bold hover:text-[#ffff]">
                      Get started
                    </button>
                  </Link>
                </div>
              </div>
              <div className="hidden md:block">
                <img src={hero2Img} alt="" className="" />
              </div>
              <div className="block md:hidden">
                <img src={consultancy} alt="" className="" />
              </div>
            </div>
          </div>

          <div>
            <div className="flex flex-col md:flex-row justify-center w-[85%] m-auto items-center gap-5 lg:h-[100vh] md:h-[450px] my-5 lg:my-[unset]">
              <div className="flex flex-col gap-5">

                <div className="font-bold text-[14px] md:text-[32px] lg:text-[42px] text-[#3D475B] lg:w-[643px] md:w-[420px] w-[205px]"
                  style={{ fontFamily: "chubbo" }}>
                <p>In <span className="text-[#273B9D]">electrical engineering,</span> creation extends beyond <span className="text-[#B80A47]">circuits;</span> we turn visions into <span className="text-[#0D93F4]">power</span>."</p>
                </div>
                <p className="font-medium text-[14px] leading-[21px] text-[#6F748D] lg:w-[594px] md:w-[360px] w-[313px]">
                We have expertise in installation, maintenance, and repair of electrical systems and equipment. This includes transformer installation, wiring, circuits, electrical panels, and devices such as switches, outlets, and lighting fixtures.
                </p>

                <Link to="/contact">
                  <button className="btn flex justify-center items-center border-[4px] border-[#273B9D] px-[28px] py-[8px] xl:w-[40%] rounded-full text-[#3D475B] text-[20px] font-bold hover:text-[#ffff] ">
                    Get started
                  </button>
                </Link>
              </div>
              <div className="">
                <img src={roundedCable} alt=""  className="w-[300px] md:w-[unset]"/>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </>
  );
};
