import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const ForgotPasswordForm = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://slobajdb.onrender.com/api/v1/users/forgotPassword', { email });
      setMessage(response.data.message);
      setEmail(''); // Reset the form
      setTimeout(() => {
        navigate('/reset-password'); // Redirect to the reset password page
      }, 2000); // Redirect after 2 seconds
    } catch (error) {
      setMessage(error.response.data.message);
    }
  };

  return (
    <div className="container mx-auto p-4 flex   h-screen justify-center items-center">
      <form onSubmit={handleSubmit} className="space-y-4 w-[30%]">
        <h2 className="text-xl font-bold">Forgot Password</h2>
        <div className="flex flex-col">
          <label className="mb-2 font-semibold">Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="px-4 py-2 border rounded outline-none"
          />
        </div>
        <button type="submit" className="w-full px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600">
          Verify Email
        </button>
        {message && <p className="mt-4 text-green-500">{message}</p>}
      </form>
    </div>
  );
};

export default ForgotPasswordForm;
