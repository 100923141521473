import React, { useState } from 'react';
import axios from 'axios';

const Form = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    projects: '',
    message: '',
    agreeToCalls: false,
    agreeToUpdates: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://slobajdb.onrender.com/api/v1/submit-form', formData);
      alert('Message Sent successfully');
      setFormData({
        name: '',
        email: '',
        phone: '',
        projects: '',
        message: '',
        agreeToCalls: false,
        agreeToUpdates: false,
      });
    } catch (error) {
      console.error('Error submitting form:', error.response ? error.response.data : error.message);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col mt-10 gap-5 w-full md:w-[690px]">
      <input
        type="text"
        placeholder="Name"
        name="name"
        id="userName"
        required
        value={formData.name}
        onChange={handleChange}
        className="w-full h-[55px] border-[1px] p-2 rounded-lg bg-[#F3F6F8] outline-none"
      />
      <input
        type="text"
        placeholder="Email"
        id="user_email"
        name="email"
        required
        value={formData.email}
        onChange={handleChange}
        className="w-full h-[55px] border-[1px] p-2 rounded-lg bg-[#F3F6F8] outline-none"
      />
      <input
        type="tel"
        name="phone"
        id="user_phone"
        placeholder="Phone number"
        required
        value={formData.phone}
        onChange={handleChange}
        className="w-full h-[55px] border-[1px] p-2 rounded-lg bg-[#F3F6F8] outline-none"
      />
      <select
        name="projects"
        id="projects"
        value={formData.projects}
        onChange={handleChange}
        className="w-full h-[55px] border-[1px] p-2 rounded-lg bg-[#F3F6F8] outline-none text-[#6F748D] opacity-[0.7]"
      >
        <option value="">Select a project</option>
        <option value="projects">Project 1</option>
        <option value="others">Others</option>
      </select>
      <textarea
        name="message"
        id="user_Message"
        placeholder="Message"
        required
        value={formData.message}
        onChange={handleChange}
        className="bg-[#F3F6F8] outline-none p-[16px] h-[154px]"
      ></textarea>
      <div className="flex gap-2 font-satosi font-medium text-[14px] text-[#6F748D]">
        <input
          type="checkbox"
          id="agreeToCalls"
          name="agreeToCalls"
          checked={formData.agreeToCalls}
          onChange={handleChange}
          required
        />
        <label htmlFor="agreeToCalls">Yes, I agree to receive calls and emails from Slobaj</label>
      </div>
      <div className="flex gap-2 font-satosi font-medium text-[14px] text-[#6F748D]">
        <input
          type="checkbox"
          id="agreeToUpdates"
          name="agreeToUpdates"
          checked={formData.agreeToUpdates}
          onChange={handleChange}
          required
        />
        <label htmlFor="agreeToUpdates">Yes, I agree to receive updates and newsletters from Slobaj</label>
      </div>
      <button type="submit" className="flex justify-center bg-[#273B9D] text-[#ffff] text-[20px] items-center lg:h-[64px] h-[unset] font-supreme rounded-full py-[10px]">
        Submit
      </button>
    </form>
  );
};

export default Form;
