import React from "react";
import { Route, Routes } from "react-router-dom";
import About from "../about_us/About";
import App from "../../App";
import OurServices from "../our_services/OurServices";
import OurProjects from "../our_projects/OurProjects";
import Contact from "../contact_us/Contact";
import ScrollToTop from "../../assets/ScrollUp";
import Dashboard from "../dashboard/Dashboard";
import Login from "../contact_us/Login";
import UpdatePasswordForm from "../dashboard/UpdatePasswordForm";
import ForgotPasswordForm from "../contact_us/ForgotPasswordForm";
import ResetPasswordForm from "../contact_us/resetPasswordForm";
const Index = () => {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<OurServices />} />
        <Route path="/projects" element={<OurProjects />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/login" element={<Login />} />
        <Route path="/updatepassword" element={<UpdatePasswordForm />} />
        <Route path="/forgot-password" element={<ForgotPasswordForm />} />
        <Route path="/reset-password" element={<ResetPasswordForm/>} />
        <Route path="/dashboard" element={<Dashboard />} />
      </Routes>
    </>
  );
};

export default Index;
