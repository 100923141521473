import React,{useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { brandLogo } from "../../assets";
import MobileFooter from "./MobileFooter";

const Footer = () => {

  const [currentYear, setCurrentYear] = useState();

  useEffect(() => {
    setCurrentYear(new Date().getFullYear());
  }, []);

  const navigate = useNavigate();

  const smoothScrollTo = (id, path) => {
    const element = document.getElementById(id);
    if (element) {
      window.scrollTo({
        behavior: "smooth",
        top: element.offsetTop - 100,
      });
    } else if (path) {
      navigate(path);
    }
  };

  return (
    <>
      <div
        className="hidden md:flex flex-col bg-[#273B9D] text-[#FFFFFF] font-supreme w-full py-10"
        style={{ fontFamily: "supreme-100" }}
      >
        <div className="flex justify-between w-[85%] m-auto">
          <div className="flex flex-col gap-5 w-[251px]">
            
            <div className="flex flex-col font-medium text-[14px] leading-[21px] gap-5">
              <p className="w-[220px] h-[84px]">
              Lagos Head Office - St. Peters House: 6th Floor <br /> 3, Ajele Street, Off Broad Street, Lagos island, lagos state
              </p>
              <p className="w-[251px]">Ibadan branch- 7/8 Otudeko Street Anfani off Challenge Ibadan , Oyo state</p>
              <p className="w-[220px]">Abuja branch - 22 Gwandu Street, AREA 11 Gariki Abuja</p>
              <p className=" underline">Info@slobaj.com</p>
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <p
              className="font-bold text-[20px] leading-[30px]"
              style={{ fontFamily: "chubbo" }}
            >
              Services
            </p>
            <ul className="flex flex-col font-medium text-[14px] leading-[21px] gap-3">
              <li onClick={() => smoothScrollTo("construction", "/")} className="cursor-pointer">Construction</li>
              <li onClick={() => smoothScrollTo("engineering", "/")} className="cursor-pointer">Engineering</li>
              <li onClick={() => smoothScrollTo("procurement", "/")} className="cursor-pointer">Procurement</li>
              <li onClick={() => smoothScrollTo("maintainance", "/")} className="cursor-pointer">Maintenance</li>
            </ul>
          </div>

          <div className="flex flex-col gap-2">
            <p
              className="font-bold text-[20px] leading-[30px]"
              style={{ fontFamily: "chubbo" }}
            >
              Company
            </p>
            <ul className="flex flex-col font-medium text-[14px] leading-[21px] gap-3">
              <li onClick={() => smoothScrollTo("team", "/about")} className="cursor-pointer">Our team</li>
              <li onClick={() => smoothScrollTo("about", "/about")} className="cursor-pointer">About us</li>
            </ul>
          </div>

          <div className="flex flex-col gap-2">
            <p
              className="font-bold text-[20px] leading-[30px]"
              style={{ fontFamily: "chubbo" }}
            >
              Projects
            </p>
            <ul className="flex flex-col font-medium text-[14px] leading-[21px] gap-3">
              <li>Completed</li>
              <li onClick={() => smoothScrollTo("client", "/projects#client")} className="cursor-pointer">Clients</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="hidden lg:flex justify-between items-center w-[85%] m-auto py-2">
        <img src={brandLogo} alt="" />
        <p className="text-[#6F748D] font-normal text-[16px] leading-[21px]" style={{ fontFamily: "supreme-100" }}>&copy; {currentYear} Slobaj.All rights reserved</p>
      </div>
      <MobileFooter className="block md:hidden" />
    </>
  );
};

export default Footer;
