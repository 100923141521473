import React, { useState } from "react";
import { brandLogo } from "../../assets";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <div className="w-[100% sticky top-0 z-10 bg-[#ffff] py-3 shadow-lg font-supreme" style={{fontFamily:"supreme"}}>
        <div className="flex justify-between  items-center   w-[85%] m-auto ">
          <img src={brandLogo} alt="" />
          <ul className="hidden md:flex gap-10 text-[#6F748D] font-medium">
            <li>
              <NavLink
                exact
                to="/"
                className={({ isActive }) => (isActive ? "text-[#273B9D]" : "")}
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/services"
                className={({ isActive }) => (isActive ? "text-[#273B9D]" : "")}
              >
                Services
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/projects"
                className={({ isActive }) => (isActive ? "text-[#273B9D]" : "")}
              >
                Projects
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/about"
                className={({ isActive }) => (isActive ? "text-[#273B9D]" : "")}
              >
                About Us
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/login"
                className={({ isActive }) => (isActive ? "text-[#273B9D] " : "hidden lg:block")}
              >
                Admin
              </NavLink>
            </li>
          </ul>
          <Link to="/contact">
            <button className="btn hidden md:flex items-center border-[4px] border-[#273B9D] lg:px-[28px] md:px-[16px] lg:py-[8px] md:py-[4px] rounded-full text-[#3D475B] text-[20px] font-bold hover:text-[#ffff]">
              contact us
            </button>
          </Link>

          {/* mobile menu harmbuger  */}

          <div className="block md:hidden" onClick={toggleMenu}>
            <span
              className={`block w-8 h-1 bg-black my-1 transition-opacity duration-300 ease-in-out ${
                isOpen ? "transform rotate-45 translate-y-2" : ""
              }`}
            ></span>

            <span
              className={`block w-8 h-1 bg-black my-1 transition-opacity duration-300 ease-in-out ${
                isOpen ? "opacity-0" : ""
              }`}
            ></span>

            <span
              className={`block w-8 h-1 bg-black my-1 transition-opacity duration-300 ease-in-out ${
                isOpen ? "transform -rotate-45 -translate-y-2" : ""
              }`}
            ></span>
          </div>
        </div>
      </div>
      {/* mobile menu  */}
      <div
        className={`fixed top-5 h-screen w-full z-10 right-0 mt-12 p-4 bg-white border rounded-lg shadow-lg transition-transform duration-300 ease-in-out ${
          isOpen ? "transform translate-x-0" : "transform translate-x-full"
        }`}
       style={{fontFamily:"supreme"}}>
        <ul className="flex flex-col gap-10 text-[25px] text-[#6F748D] font-bold w-[85%] m-auto pt-5">
          <li>
            <NavLink
              exact
              to="/"
              className={({ isActive }) => (isActive ? "text-[#273B9D]" : "")}
            >
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/services"
              className={({ isActive }) => (isActive ? "text-[#273B9D]" : "")}
            >
              Services
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/projects"
              className={({ isActive }) => (isActive ? "text-[#273B9D]" : "")}
            >
              Projects
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/about"
              className={({ isActive }) => (isActive ? "text-[#273B9D]" : "")}
            >
              About Us
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/contact"
              className={({ isActive }) => (isActive ? "text-[#273B9D]" : "")}
            >
              Contact Us
            </NavLink>
          </li>
        </ul>
      </div>
    </>
  );
};

export default NavBar;
