import React from 'react';
import { useNavigate } from 'react-router-dom';

const LogoutButton = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Remove the token from local storage
    localStorage.removeItem('token');
    // Redirect to the home page
    navigate('/');
  };

  return (
    <button onClick={handleLogout} className=" bg-gray-500 text-white p-2 rounded">
      Logout
    </button>
  );
};

export default LogoutButton;
