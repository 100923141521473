import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const ResetPasswordForm = () => {
  const [token, setToken] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://slobajdb.onrender.com/api/v1/users/resetPassword', { token, newPassword });
      setMessage(response.data.message);
      setToken(''); // Reset the form
      setNewPassword(''); // Reset the form
      setTimeout(() => {
        navigate('/login'); // Redirect to the login page
      }, 2000); // Redirect after 2 seconds
    } catch (error) {
      setMessage(error.response.data.message);
    }
  };

  return (
    <div className="container mx-auto flex h-screen justify-center items-center">
      <form onSubmit={handleSubmit} className="space-y-4 w-[30%]">
        <h2 className="text-xl font-bold">Reset Password</h2>
        <div className="flex flex-col">
          <label className="mb-2 font-semibold">Token:</label>
          <input
            type="text"
            value={token}
            onChange={(e) => setToken(e.target.value)}
            required
            className="px-4 py-2 border rounded outline-none"
          />
        </div>
        <div className="flex flex-col">
          <label className="mb-2 font-semibold">New Password:</label>
          <input
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
            className="px-4 py-2 border rounded outline-none"
          />
        </div>
        <button type="submit" className="w-full px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600">
          Reset Password
        </button>
        {message && <p className="mt-4 text-green-500">{message}</p>}
      </form>
    </div>
  );
};

export default ResetPasswordForm;
