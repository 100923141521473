import React,{useEffect} from "react";
import "aos/dist/aos.css";
import AOS from "aos";
import {
  ancentSquare,
  blackcabels,
  doubleFuseBox,
  earthing,
  electricPole1,
  fusebox,
  squartz,
  transformer1,
} from "../../assets";

const TransformerProject = () => {
  useEffect(() => {
    AOS.init({ duration: 700 });
  }, []);
  return (
    <div className="flex" style={{fontFamily:"supreme"}}>
        <img src={ancentSquare} alt="" className="hidden md:block w-[4%]" />
      <div className="flex justify-center items-center font-chubbo gap-3">
        <div className="flex flex-col gap-3 w-[85%] md:w-[94%] m-aut">
          <p data-aos="fade-left" className="font-bold text-[20px] lg:text-[32px] text-[#3D475B]" style={{fontFamily:"chubbo"}}>
            Funaab Transformer Installations
          </p>

          <div className="flex gap-3 md:gap-[40px]">
            <div>
              <img src={electricPole1} alt={""} />
            </div>

            <div className="flex gap-3">
              <div className="flex flex-col gap-3">
                <img src={blackcabels} alt={""} />
                <img src={fusebox} alt={""} />
                <img src={earthing} alt={""} />
              </div>

              <div className="flex flex-col gap-3">
                <img src={doubleFuseBox} alt="" />
                <img src={transformer1} alt="" />
              </div>
            </div>
          </div>

        </div>
      </div>
        <img src={squartz} alt="" className="hidden md:block w-[4% relative md:top-[16em] lg:top-[21em] right-14 -mr-14"  />
    </div>
  );
};

export default TransformerProject;
