import React from 'react'
import { Construction } from './HeroPage'
import { OurClient } from './OurClient'
import Projects from './Projects'
import Services from './Services'
import NavBar from '../nav_bar/NavBar'
import Footer from '../footer/Footer'
import ContactBtn from '../news_letter/ContactBtn'

const Home = () => {
  return (
    <>
    <NavBar/>
      <Construction />
      <OurClient />
      < Services />
      <Projects />
      <ContactBtn />
      <Footer/>
    </>
  )
}

export default Home