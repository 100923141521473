import React from "react";
import { Link } from "react-router-dom";

const ContactBtn = () => {
  return (
    <div className="flex justify-center items-center my-10">
      <div
        data-aos="fade-right"
        className="flex flex-col md:flex-row justify-between items-center rounded-[24px] bg-[#273B9D] py-[12px] px-[20px] w-[85%] text-center md:text-start lg:h-[120px] h-[unset] overflow-hidden"
      >
        <p
          className="font-bold text-[18px] md:text-[20px] lg:text-[32px] text-[#FFFFFF]"
          style={{ fontFamily: "chubbo" }}
        >
          Do you need to consult with us?
        </p>
        <Link to="/contact">
          <button className="flex font-bold text-[20px] text-[#000000] bg-[#ffff] border-[4px] border-[#273B9D] py-[8px] md:py-[16px] px-[20px] md:px-[40px] rounded-full h-[62px] justify-center items-center">
            Contact us
          </button>
        </Link>
      </div>
    </div>
  );
};

export default ContactBtn;
