import React, { useEffect } from "react";
import "aos/dist/aos.css";
import AOS from "aos";
import {
  arrow,
  project1,
  project2,
  project3,
  project4,
  project5,
  project6,
} from "../../assets";
import { Link } from "react-router-dom";

const Projects = () => {
  useEffect(() => {
    AOS.init({ duration: 700 });
  }, []);
  return (
    <>
      <div
        className="flex flex-col gap-5 w-[85%] m-auto my-20 overflow-hidden"
        style={{ fontFamily: "supreme" }}
      >
        <div className="flex justify-between items-center">
          <p
            className="font-bold text-[24px] lg:text-[42px] leading-[unset] lg:leading-[63px] w-[85%"
            style={{ fontFamily: "chubbo" }}
          >
            Our Projects
          </p>

          <Link to="/projects">
            <button className="group flex items-center justify-center border-[2px] border-[#273B9D] rounded-full md:h-[3em] h-[3em] transition-all duration-300 hover:w-[12em] w-[4em] relative hover:left-20 hover:-z-20 ">
              <div className="flex items-center w-full hover:items-center hover:justify-center">
                <img
                  src={arrow}
                  alt=""
                  className=" transition-transform duration-300 group-hover:rotate-45"
                />
                <span className="ml-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 text-[#3D475B] whitespace-nowrap">
                  Explore More
                </span>
              </div>
            </button>
          </Link>
        </div>

        <div className="flex flex-col justify-center items-center gap-5 overflow-hidden">
          <div data-aos="fade-right" className="flex gap-5 flex-col md:flex-row ">
            <div>
              <img src={project1} alt={""} />
            </div>
            <div>
              <img src={project2} alt={""} />
            </div>
            <div>
              <img src={project3} alt={""} />
            </div>
          </div>
          <div data-aos="fade-left" className="hidden md:flex gap-5">
            <div>
              <img src={project4} alt={""} />
            </div>
            <div>
              <img src={project5} alt={""} />
            </div>
            <div>
              <img src={project6} alt={""} />
            </div>
          </div>

          <div data-aos="fade-left" className="md:hidden flex flex-col gap-5">
            <div>
              <img src={project4} alt={""} />
            </div>
            <div className="flex">
            <div>
              <img src={project5} alt={""} />
            </div>
            <div>
              <img src={project6} alt={""} />
            </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Projects;
