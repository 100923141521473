import React, { useState } from 'react';
import axios from 'axios';

const UpdatePasswordForm = ({ onClose }) => {
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      const response = await axios.put(
        'https://slobajdb.onrender.com/api/v1/users/updatePassword',
        { password },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMessage(response.data.data);
      setPassword(''); // Clear the password field after a successful update
    } catch (error) {
      setMessage(error.response.data.message);
    }
  };

  return (
    <div className="fixed top-0 left-0 right-0 z-50 p-4 bg-white shadow-lg transform transition-transform -translate-y-full">
      <button
        className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-2"
        onClick={onClose}
      >
        &times;
      </button>
      <form onSubmit={handleSubmit} className="space-y-4">
        <h2 className="text-xl font-bold">Update Password</h2>
        <div className="flex flex-col">
          <label className="mb-2 font-semibold">New Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="px-4 py-2 border rounded outline-none"
          />
        </div>
        <button
          type="submit"
          className="w-full px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
        >
          Update Password
        </button>
        {message && <p className="mt-4 text-green-500">{message}</p>}
      </form>
    </div>
  );
};

export default UpdatePasswordForm;
