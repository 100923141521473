import React from "react";

import {
  firoproject1,
  firoproject2,
  firoproject3,
  firoproject4,
  firoproject5,
  firoproject6,
  greySquare,
} from "../../assets";

const FiroProject = () => {
  return (
    <div className="flex">
      <img src={greySquare} alt="" className="hidden md:block relative top-20 w-[4%]" />
      <div className="flex justify-center items-center my-5 md:my-20">
        <div className="flex flex-col gap-3 w-[85%] md:w-[93%] m-auto">
          <p data-aos="fade-right" className="font-bold text-[20px] lg:text-[32px] text-[#3D475B]" style={{fontFamily:"chubbo"}}>Firo Project</p>

          <div className="flex items-center gap-3 md:gap-[40px]">
            <div>
              <img src={firoproject1} alt=""  />
            </div>
            <div className="flex items-center gap-3">
              <div className="flex flex-col gap-3">
                <div>
                <img src={firoproject2} alt="" />
                </div>
                <div>
                <img src={firoproject4} alt="" />
                </div>
                <div>
                <img src={firoproject5} alt="" />
                </div>
              </div>
              <div className="flex flex-col gap-3">
                <div>
                <img src={firoproject3} alt="" />
                </div>

                <div>
                  <img src={firoproject6} alt="" className=""/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FiroProject;
