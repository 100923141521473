import React, { useEffect } from "react";
import "aos/dist/aos.css";
import AOS from "aos";
import { Link } from "react-router-dom";
import {
  arrow,
  cilvilEngr,
  consultancy,
  customdesignimg,
  maintainancewk1,
  oilGas2,
  transformer2,
} from "../../assets";

const Services = () => {
  useEffect(() => {
    AOS.init({ duration: 700 });
  }, []);

  return (
    <>
      <div className="flex flex-col font-chubbo w-[85%] m-auto gap-10 lg:mb-20 mb-0 my-10 lg:my-0">
        <div className="flex flex-col md:flex-row md:justify-between gap-5">
          <p
            data-aos="fade-up"
            className="flex text-[24px] lg:text-[42px] font-bold lg:leading-[63px] leading-[unset] w-full md:w-[35%]"
            style={{ fontFamily: "chubbo" }}
          >
            A Comprehensive set of our Services
          </p>

          <Link to="/services">
            <button className="group hidden lg:flex items-center justify-center border-[2px] border-[#273B9D] rounded-full md:h-[3em] h-[3em] transition-all duration-300 hover:w-[12em] w-[4em]">
              <div className="flex items-center w-full hover:items-center hover:justify-center">
                <img
                  src={arrow}
                  alt=""
                  className=" transition-transform duration-300 group-hover:rotate-45"
                />
                <span
                  className="ml-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 text-[#3D475B] whitespace-nowrap"
                  style={{ fontFamily: "supreme" }}
                >
                  Explore More
                </span>
              </div>
            </button>
          </Link>

          <p className="flex font-medium text-[16px] leading-[24px] text-[#6F748D] w-full md:w-[486px] justify-start">
            At Slobaj, trust is the foundation of our relationships. With a
            commitment to excellence, transparent communication, and
            collaborative teamwork, we are your trusted building partner. Our
            experience, integrity, and client-centric approach ensure that your
            project is in capable hands from start to finish."
          </p>
        </div>

        <div className="flex flex-col gap-5">
          <div
            id="construction"
            data-aos="fade-down"
            className="flex flex-col md:flex-row gap-3 overflow-hidden"
          >
            <div className="flex flex-col gap-3 w-full md:w-[33.3%">
                <img src={cilvilEngr} alt="" />
              <p
                className="font-bold text-[24px]"
                style={{ fontFamily: "chubbo" }}
              >
                Civil Engineering{" "}
              </p>
              <p className="font-medium text-[14px] text-[#6F748D] leading-[21px]">
                Our team of professionals blend creativity, knowledge and
                analytical tools by applying problem solving techniques of
                engineering centred on creating technologies to reach human
                needs.
              </p>
            </div>

            <div className="flex flex-col gap-3 w-full md:w-[33.3%">
                <img src={transformer2} alt="" />
              <p
                className="font-bold text-[24px]"
                style={{ fontFamily: "chubbo" }}
              >
                Electrical Engineering{" "}
              </p>
              <p className="font-medium font-supreme text-[14px] text-[#6F748D] leading-[21px]">
                Our team of professionals blend creativity, knowledge and
                analytical tools by applying problem solving techniques of
                engineering centred on creating technologies to reach human
                needs.
              </p>
            </div>
          </div>

          <div
            data-aos="fade-up"
            id="maintainance"
            className="flex flex-col md:flex-row gap-4"
          >

            <div className="flex flex-col gap-3 w-full md:w-[33.3%">
                <img src={maintainancewk1} alt="" />
              <p
                className="font-bold text-[24px]"
                style={{ fontFamily: "chubbo" }}
              >
                Maintenance
              </p>
              <p className="font-medium text-[14px] text-[#6F748D] leading-[21px] xl:w-[375px] w-full">
                We help in keeping the the standard of your facilities up to
                date by providing the necessary maintenance for your facilities.
              </p>
            </div>
            <div className="flex flex-col gap-3 w-full md:w-[33.3%">
                <img src={oilGas2} alt="" />
              <p
                className="font-bold text-[24px]"
                style={{ fontFamily: "chubbo" }}
              >
                Upstream & Downstream
              </p>
              <p className="font-medium text-[14px] text-[#6F748D] leading-[21px] xl:w-[375px w-full">
              Our team of professionals blend creativity, knowledge and analytical tools by applying problem solving techniques of engineering centred on creating technologies to reach human needs.
              </p>
            </div>
          </div>

          <div data-aos="fade-down" className="flex flex-col md:flex-row gap-4">
            <div className="flex flex-col gap-3 w-full md:w-[33.3%">
                <img src={customdesignimg} alt="" />
              <p
                className="font-bold text-[24px]"
                style={{ fontFamily: "chubbo" }}
              >
                Custom designs
              </p>
              <p className="font-medium text-[14px] text-[#6F748D] leading-[21px] w-full">
              Through our custom architecture designs, we partner with clients, providing guidance and support to create a livable and sustainable future, thereby enhancing community growth.
              </p>
            </div>

            <div
              data-aos="fade-up"
              className="flex flex-col gap-3 w-full md:w[33.3%"
            >
                <img src={consultancy} alt="" />
              <p
                className="font-bold text-[24px]"
                style={{ fontFamily: "chubbo" }}
              >
                Consultancy{" "}
              </p>
              <p className="font-medium text-[14px] text-[#6F748D] leading-[21px] w-full">
                offer valuable expertise and guidance for your businesses
                seeking to improve performance, address challenges, trainings
                and achieve success, so organisations can make informed
                decisions and support their growth and prosperity.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
