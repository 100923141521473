import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useReactToPrint } from "react-to-print";
import LogoutButton from "../contact_us/Logout";
import UpdatePasswordForm from "./UpdatePasswordForm";

const Dashboard = () => {
  const [forms, setForms] = useState([]);
  const [error, setError] = useState(null);
  const componentRef = useRef();

  const [showForm, setShowForm] = useState(false);

  const handleToggleForm = () => {
    setShowForm(!showForm);
  };

  useEffect(() => {
    const fetchForms = async () => {
      try {
        const response = await axios.get(
          "https://slobajdb.onrender.com/api/v1/forms"
        );
        setForms(response.data.data);
      } catch (error) {
        setError(error.response ? error.response.data.message : error.message);
      }
    };

    fetchForms();
  }, []);

  const handleDelete = async (id) => {
    console.log(`Deleting form with ID: ${id}`); // Log the ID for debugging
    try {
      await axios.delete(`https://slobajdb.onrender.com/api/v1/forms/${id}`);
      setForms(forms.filter((form) => form._id !== id));
    } catch (error) {
      console.error(
        "Error deleting form:",
        error.response ? error.response.data.message : error.message
      );
    }
  };

  const handleDeleteAll = async () => {
    try {
      await axios.delete("https://slobajdb.onrender.com/api/v1/forms");
      setForms([]);
    } catch (error) {
      console.error(
        "Error deleting all forms:",
        error.response ? error.response.data.message : error.message
      );
    }
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleDownload = () => {
    const csvData = forms.map((form) => ({
      Name: form.name,
      Email: form.email,
      Phone: form.phone,
      Projects: form.projects,
      Message: form.message,
      AgreeToCalls: form.agreeToCalls,
      AgreeToUpdates: form.agreeToUpdates,
      CreatedAt: new Date(form.createdAt).toLocaleString(),
    }));

    const csvHeader =
      "Name,Email,Phone,Projects,Message,AgreeToCalls,AgreeToUpdates,CreatedAt\n";
    const csvRows = csvData
      .map((row) => Object.values(row).join(","))
      .join("\n");
    const csvString = `${csvHeader}${csvRows}`;
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "forms.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  if (error) {
    return <p className="text-red-500">{error}</p>;
  }

  return (
    <div
      className="p-6"
      ref={componentRef}
      style={{ fontFamily: "supreme-100" }}
    >
      <div className="flex justify-between items-center">
        <h2
          className="text-3xl mb-4 font-bold"
          style={{ fontFamily: "chubbo" }}
        >
          Admin Dashboard
        </h2>
        <LogoutButton />
      </div>

      <table className="min-w-full bg-white">
        <thead>
          <tr>
          <th>
              <button
                onClick={handleToggleForm}
                className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
              >
                {showForm ? "Update . . ." : "Update"}
              </button>
            </th>
           
            <th>
              <button
                onClick={handleDownload}
                className="mr-2 bg-green-500 text-white p-2 rounded"
              >
                Download
              </button>
            </th>
            <th>
              <button
                onClick={handlePrint}
                className="mr-2 bg-blue-500 text-white p-2 rounded"
              >
                Print
              </button>
            </th>
            
            <th>
              <button
                onClick={handleDeleteAll}
                className="bg-red-500 text-white p-2 rounded"
              >
                Delete All
              </button>
            </th>
          </tr>
        </thead>

        <thead>
          <tr>
            <th className="py-2 px-4 border-b">Name</th>
            <th className="py-2 px-4 border-b">Email</th>
            <th className="py-2 px-4 border-b">Phone</th>
            <th className="py-2 px-4 border-b">Projects</th>
            <th className="py-2 px-4 border-b">Message</th>
            <th className="py-2 px-4 border-b">AgreeToCalls</th>
            <th className="py-2 px-4 border-b">AgreeToUpdates</th>
            <th className="py-2 px-4 border-b">Created At</th>
            <th className="py-2 px-4 border-b">Actions</th>
          </tr>
        </thead>
        <tbody>
          {forms.map((form) => (
            <tr key={form._id}>
              <td className="py-2 px-4 border-b">{form.name}</td>
              <td className="py-2 px-4 border-b">{form.email}</td>
              <td className="py-2 px-4 border-b">{form.phone}</td>
              <td className="py-2 px-4 border-b">{form.projects}</td>
              <td className="py-2 px-4 border-b">{form.message}</td>
              <td className="py-2 px-4 border-b">
                {form.agreeToCalls ? "Yes" : "No"}
              </td>
              <td className="py-2 px-4 border-b">
                {form.agreeToUpdates ? "Yes" : "No"}
              </td>
              <td className="py-2 px-4 border-b">
                {new Date(form.createdAt).toLocaleString()}
              </td>
              <td className="py-2 px-4 border-b">
                <button
                  onClick={() => handleDelete(form._id)}
                  className="bg-red-500 text-white p-2 rounded"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>


      <div
          className={`${
            showForm ? "translate-y-0 w-[30%] m-auto" : "-translate-y-full"
          } transition-transform`}
        >
          {showForm && (
            <UpdatePasswordForm onClose={() => setShowForm(false)} />
          )}
      </div>
    </div>
  );
};

export default Dashboard;
