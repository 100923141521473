import React, { useEffect } from "react";
import "aos/dist/aos.css";
import AOS from "aos";
import {
  abjMgr1,
  ancentSquare,
  anuImg,
  greySquare,
  headIct1,
  jimojAisha1,
  magnifyingLence,
  siteEngrs,
  square1,
} from "../../assets";
import NavBar from "../nav_bar/NavBar";
import Footer from "../footer/Footer";

const About = () => {
  useEffect(() => {
    AOS.init({ duration: 700 });
  }, []);
  return (
    <>
      <NavBar />
      <div className="overflow-hidden" id="about">
        <div
          className="flex flex-col  w-[85%] m-auto my-10 gap-30"
          style={{ fontFamily: "supreme" }}
        >
          <div className="flex flex-col gap-5">
            <div
              className="flex font-bold text-[32px] lg:text-[42px] leading-[63px] text-[#3D475B]"
              style={{ fontFamily: "chubbo" }}
            >
              <p data-aos="fade-right" className="">
                Our Philosophy
              </p>
            </div>

            <div className="flex font-medium text-[16px] text-[#6F748D] leading-[24px] w-ful lg:justify-end justify-center">
              <div>
                <p data-aos="fade-left" className="lg:w-[722px] w-full">
                  Our philosophy is to provide high quality services to our
                  clients in a safe, eco-friendly and cost effective way with
                  our key values of integrity, respect, honesty, fairness and
                  teamwork. We believe in service above all since our singular
                  goal is to move our customers' business forward. This drives
                  us to anticipate our customers' needs and work with them to
                  deliver the finest products and services on time and budget.
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-5">
            <div
              className="flex font-bold text-[32px] lg:text-[42px] text-[#3D475B] lg:justify-end justify-start"
              style={{ fontFamily: "chubbo" }}
            >
              <p data-aos="fade-right" className="">
                Our Quality
              </p>
            </div>

            <div className="font-medium text-[16px] text-[#6F748D] leading-[24px] lg:w-[722px] w-full lg:h-[96px] h-[unset]">
              <p data-aos="fade-left">
                Quality has been an intrinsic part of our expertise and we make
                certain that every step we take is quality oriented, bringing
                immense satisfaction to our clients. <br /> We nourish a team of
                trained professionals, who assist us properly in maintaining
                high quality in our services.
              </p>
            </div>
          </div>
        </div>

        <div className="flex gap- w-full lg:justify-between justify-center">
          <img
            src={ancentSquare}
            alt={""}
            className="hidden lg:block w-[4% relative bottom-52 -z-10"
          />
          <div className="w-[85%]">
            <img src={siteEngrs} alt={""} />
          </div>
          <img src={square1} alt="" className="hidden lg:block" />
        </div>

        <div className="flex w-full my-20 items-center">
          <div className="hidden lg:block relative left-16 -ml-16 -z-10 w-[4%]">
            <img src={greySquare} alt="" />
          </div>

          <div
            className="flex flex-col md:flex-row gap-3 w-[85%] m-auto"
            style={{ fontFamily: "supreme" }}
          >
            <div
              data-aos="fade-up"
              className="flex flex-col gap-5 p-5 border-[1px] border-[#B80A47] w-full md:w-[50%]"
            >
              <p
                className="font-bold text-[32px] lg:text-[42px] text-[#B80A47]"
                style={{ fontFamily: "chubbo" }}
              >
                Our Mission
              </p>
              <p
                className="font-medium text-[16px] text-[#3D475B] leading-[24px] w-full"
                style={{ fontFamily: "supreme" }}
              >
                Our vision is to be the best indigenous company in Contruction,
                Engineering, Oil and gas Sector, providing our Client with
                exceptional products and services that set the standard for
                quality and innovation. Our aim is to be the best in everything
                we do, from Civil Engineering, Procurement, Consultancy,
                Construction, downstream, Upstream Oil and Gas to customer
                support and beyond. By constantly Bridging the boundaries of
                what's possible and setting the bar higher for ourselves, to
                create value for our customers and employees. At our core, we
                are a company that is passionate about grooming young talent for
                excellence and committed to delivering results that exceed
                expectations.
              </p>
            </div>

            <div
              data-aos="fade-down"
              className="flex flex-col bg-[#273B9D] gap-3 text-[#ffff] p-5 w-full md:w-[50%]"
            >
              <p
                className="font-bold text-[32px] lg:text-[42px]"
                style={{ fontFamily: "chubbo" }}
              >
                Our Vision
              </p>
              <p
                className="font-medium text-[16px] leading-[24px] w-full"
                style={{ fontFamily: "supreme" }}
              >
                Our mission is to build a formidable society that will cater for
                the present generation and the posterity benefits for the goals
                of the organisation using contemporary technological innovation
                and observing environmental Health and safety precautions on
                site.
              </p>
              <div className="flex justify-end">
                <img src={magnifyingLence} alt="" />
              </div>
            </div>
          </div>
        </div>

        <div id="team" className="flex flex-col gap-3 w-[85%] m-auto my-20">
          <p
            data-aos="fade-left"
            className="font-bold text-[32px] lg:text-[42px] text-[#3D475B]"
            style={{ fontFamily: "chubbo" }}
          >
            Our Team
          </p>
          <div
            data-aos="fade-right"
            className="flex flex-col gap-2 items-center"
          >
            <div className="flex flex-col md:flex-row gap-2">
            <div>
              <img src={jimojAisha1} alt={""} />
            </div>
            <div>
              <img src={headIct1} alt="" />
            </div>
            <div>
              <img src={abjMgr1} alt={""} />
            </div>
            </div>

             <div className="flex justify-center">
              <img src={anuImg} alt={""} className="md:w-[60%] lg:w-[unset] md:rounded-xl lg:rounded-none"/>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default About;
