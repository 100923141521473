import React from 'react'
import Project from './Project'
import TransformerProject from './TransformerProject'
import FiroProject from './FiroProject'
import NpaProject from './NpaProject'
import NavBar from '../nav_bar/NavBar'
import Footer from '../footer/Footer'
import ContactBtn from '../news_letter/ContactBtn'

const OurProjects = () => {
  return (
    <>
    <NavBar/>
      <Project />
      <TransformerProject />
      <FiroProject />
      <NpaProject />
      <ContactBtn />
      <Footer/>
    </>
  )
}

export default OurProjects